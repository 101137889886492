<!--  -->
<template>
    <div class='com-subject-summary-bcg position-center g1' :class="styleClass">
        <component-bcg url="subject/g1/bcg.png"></component-bcg>
        <!-- :style="styleData" -->
        <div class="g-bcg" >
            <div class="item" v-for="(v, i) in bcgListCul" :key="i" :class="v.class">
                <div class="item" v-for="(s, j) in v.children" :key="j" :class="s.class"></div>
            </div>
        </div>
        <div class="items-box">
            <slot name="items"></slot>
            <div class="item" v-for="(v, i) in itemsListCul" :key="i" :class="v.class">
                <div class="item" v-for="(s, j) in v.children" :key="j" :class="s.class"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            appStyle: Object,
            styleClass: String,
        },
        data() {
            return {
                itemsList: [
                    {class: 'b28', style: 'page-start'}, //宝石底座
                    {class: 'b29', style: 'page-start'}, //宝石底座
                    {class: 'b51', style: 'page-1'}, //宝石底座1
                    {class: 'b51-1', style: 'page-1'}, //宝石底座1
                    {class: 'b56', style: 'page-1'}, //宝石底座3
                    {class: 'b57', style: 'page-1'}, //宝石光3
                    {class: 'b28', style: 'page-0'}, //宝石底座
                    {class: 'b29', style: 'page-0'}, //宝石底座
                    {class: 'b65', style: 'page-end'}, //宝石底座
                    {class: 'b65-s', style: 'page-end'}, //宝石底座
                    {class: 'b66', style: 'page-end'}, //宝石底座
                    {class: 'b66-s', style: 'page-end'}, //宝石底座
                    {class: 'b67', style: 'page-end'}, //宝石底座
                    {class: 'b67-s', style: 'page-end'}, //宝石底座
                    {class: 'b68', style: 'page-end'}, //宝石底座
                    {class: 'b68-s', style: 'page-end'}, //宝石底座
                    {class: 'b69', style: 'page-end'}, //宝石底座
                    {class: 'b69-s', style: 'page-end'}, //宝石底座
                    
                ],
                bcgList: [
                    {class: 'b1', style: 'page-start'}, //星云阴影
                    {class: 'b2', style: 'page-start'}, //星云
                    {class: 'b3'}, //星光
                    {class: 'b4', style: 'page-start'}, //云朵1
                    {class: 'b5', style: 'page-start'}, //云朵2
                    {class: 'b6', style: 'page-start'}, //云朵3
                    {class: 'b11', style: 'page-start'}, //星光棋盘7
                    {class: 'b7'}, //云朵4
                    {class: 'b8'}, //云朵5
                    {class: 'b9', style: 'page-start'}, //星光棋盘1
                    {class: 'b33', style: 'page-start'}, //星光棋盘2
                    {class: 'b10', style: 'page-start'}, //星光棋盘3
                    {class: 'b12', style: 'page-start'}, //星光棋盘4
                    {class: 'b13', style: 'page-start'}, //星光棋盘6
                    {class: 'b14', style: 'page-start'}, //星光棋盘5
                    {class: 'b15', style: 'page-start'}, //远处小岛
                    {class: 'b16', style: 'page-start'}, //中间小岛
                    {class: 'b17'}, //前景右下小岛
                    {class: 'b18', style: "page-start"}, //前景左下小岛
                    {class: 'b19', style: 'page-start'}, //通道
                    {class: 'b30', style: 'page-start'}, //光带

                    {class: 'b21', style: 'page-start'}, //宝石底座
                    {class: 'b20', style: 'page-start'}, //宝石发光
                    {class: 'b23', style: 'page-start'}, //宝石底座
                    {class: 'b22', style: 'page-start'}, //宝石发光
                    {class: 'b25', style: 'page-start'}, //大宝石
                    {class: 'b24', style: 'page-start'}, //大宝石发光
                    {class: 'b26', style: 'page-start'}, //宝石底座
                    {class: 'b27', style: 'page-start'}, //宝石发光
                    
                    // {class: 'b31', style: 'page-start'}, //星星关卡1光点
                    {class: 'b32', style: 'page-start'}, //起点
                    // {class: 'b34', style: 'page-start'}, //星星关卡2光点
                    // {class: 'b35', style: 'page-start'}, //星星关卡3光点

                    // page 2
                    
                    {class: 'b45', style: 'page-1'}, //星云阴影
                    {class: 'b44', style: 'page-1'}, //星云
                    {class: 'b38', style: 'page-1'}, //云朵1
                    {class: 'b39', style: 'page-1'}, //云朵2
                    {class: 'b40', style: 'page-1'}, //云朵3
                    {class: 'b41', style: 'page-1'}, //星光棋盘3
                    {class: 'b42', style: 'page-1'}, //星光棋盘2
                    {class: 'b43', style: 'page-1'}, //星光棋盘6
                    {class: 'b46', style: 'page-1'}, //星光棋盘5
                    {class: 'b47', style: 'page-1'}, //星光棋盘7
                    {class: 'b50', style: 'page-1'}, //星光棋盘4
                    {class: 'b48', style: 'page-1'}, //中间小岛
                    {class: 'b49', style: 'page-1'}, //前景坐下小岛
                    {class: 'b36', style: 'page-1'}, //通道
                    {class: 'b37', style: 'page-1'}, //光带
                    {class: 'b52', style: 'page-1'}, //宝石底座2
                    {class: 'b53', style: 'page-1'}, //宝石光2
                    {class: 'b54', style: 'page-1'}, //大宝石
                    {class: 'b55', style: 'page-1'}, //大宝石光
                    
                    {class: 'b58', style: 'page-1'}, //宝石底座4
                    {class: 'b59', style: 'page-1'}, //宝石底座4
                    // {class: 'b72', style: 'page-1'}, //星星关卡3光点
                    // {class: 'b73', style: 'page-1'}, //星星关卡3光点
                    // {class: 'b74', style: 'page-1'}, //星星关卡3光点
                    // {class: 'b75', style: 'page-1'}, //星星关卡3光点

                    //page 3
                    {class: 'b1', style: 'page-0'}, //星云阴影
                    {class: 'b2', style: 'page-0'}, //星云
                    {class: 'b4', style: 'page-0'}, //云朵1
                    {class: 'b5', style: 'page-0'}, //云朵2
                    {class: 'b6', style: 'page-0'}, //云朵3
                    {class: 'b11', style: 'page-0'}, //星光棋盘7
                    {class: 'b9', style: 'page-0'}, //星光棋盘1
                    {class: 'b33', style: 'page-0'}, //星光棋盘2
                    {class: 'b10', style: 'page-0'}, //星光棋盘3
                    {class: 'b12', style: 'page-0'}, //星光棋盘4
                    {class: 'b13', style: 'page-0'}, //星光棋盘6
                    {class: 'b14', style: 'page-0'}, //星光棋盘5
                    {class: 'b15', style: 'page-0'}, //远处小岛
                    {class: 'b16', style: 'page-0'}, //中间小岛
                    {class: 'b18', style: "page-0"}, //前景左下小岛
                    {class: 'b60', style: 'page-0'}, //通道
                    {class: 'b61', style: 'page-0'}, //光带

                    {class: 'b21', style: 'page-0'}, //宝石底座
                    {class: 'b20', style: 'page-0'}, //宝石发光
                    {class: 'b23', style: 'page-0'}, //宝石底座
                    {class: 'b22', style: 'page-0'}, //宝石发光
                    {class: 'b25', style: 'page-0'}, //大宝石
                    {class: 'b24', style: 'page-0'}, //大宝石发光
                    {class: 'b26', style: 'page-0'}, //宝石底座
                    {class: 'b27', style: 'page-0'}, //宝石发光
                    
                    // {class: 'b31', style: 'page-0'}, //星星关卡1光点
                    // {class: 'b34', style: 'page-0'}, //星星关卡2光点
                    // {class: 'b35', style: 'page-0'}, //星星关卡3光点
                    // {class: 'b62', style: 'page-0'}, //星星关卡3光点

                    //page end
                    {class: 'b1', style: 'page-end'}, //星云阴影
                    {class: 'b2', style: 'page-end'}, //星云
                    {class: 'b4', style: 'page-end'}, //云朵1
                    {class: 'b5', style: 'page-end'}, //云朵2
                    {class: 'b6', style: 'page-end'}, //云朵3
                    {class: 'b11', style: 'page-end'}, //星光棋盘7
                    {class: 'b9', style: 'page-end'}, //星光棋盘1
                    {class: 'b33', style: 'page-end'}, //星光棋盘2
                    {class: 'b10', style: 'page-end'}, //星光棋盘3
                    {class: 'b12', style: 'page-end'}, //星光棋盘4
                    {class: 'b13', style: 'page-end'}, //星光棋盘6
                    {class: 'b14', style: 'page-end'}, //星光棋盘5
                    {class: 'b15', style: 'page-end'}, //远处小岛
                    {class: 'b16', style: 'page-end'}, //中间小岛
                    {class: 'b18', style: "page-end"}, //前景左下小岛
                    {class: 'b63', style: 'page-end'}, //通道
                    {class: 'b64', style: 'page-end'}, //光带

                    {class: 'b21', style: 'page-end'}, //宝石底座
                    {class: 'b20', style: 'page-end'}, //宝石发光
                    {class: 'b23', style: 'page-end'}, //宝石底座
                    {class: 'b22', style: 'page-end'}, //宝石发光
                    {class: 'b25', style: 'page-end'}, //大宝石
                    {class: 'b24', style: 'page-end'}, //大宝石发光
                    
                    // {class: 'b31', style: 'page-end'}, //星星关卡1光点
                    // {class: 'b34', style: 'page-end'}, //星星关卡2光点
                ]
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            bcgListCul(){
                let {bcgList: list, styleClass} = this
                let rd = []
                for(let i in list){
                    if(list[i].style !== undefined && styleClass != list[i].style) continue
                    rd.push(list[i])
                }
                return rd
            },
            itemsListCul(){
                let {itemsList: list, styleClass} = this
                let rd = []
                for(let i in list){
                    if(list[i].style !== undefined && styleClass != list[i].style) continue
                    rd.push(list[i])
                }
                return rd
                
            },
            styleData(){
                let {appSize} = this._common
                if(!appSize) return {}
                return {
                    width: `${appSize.width}px`
                }
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前c2
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    @import './css/g1_start_end_0.less';
    @import './css/g1_start.less';
    @import './css/g1_1.less';
    @import './css/g1_0.less';
    @import './css/g1_end.less';
    .com-subject-summary-bcg.g1{
        width: 100vw;
        height: 100vh;
        // width: 667px;
        // height: 375px;
        .item-params(@width, @height, @left: auto, @top: auto, @zIndex: 0){
            width: @width;
            height: @height;
            left: @left;
            top: @top;
            z-index:  @zIndex;
        }
        
        .items-box, .g-bcg{
            position: absolute;
            width: @designWidth;
            height: @designHeight;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .items-box{
            z-index: 1;
            .puzzle-item{
                position: absolute;
            }
        }
        .g-bcg{
            
            z-index: 0;
            .item{
                position: absolute;
            }
            
            
            .b3{
                .item-params(630px, 310px, 10px, 38px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g1/b3.png'));
            }
            .b7{
                .item-params(166px, 54px, 158px, 320px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g1/b7.png'));
            }
            .b8{
                .item-params(153px, 33px, 378px, 342px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g1/b8.png'));
            }
            .b17{
                .item-params(111px, 125px, 556px, 250px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g1/b17.png'));
            }
            
            
        }
    }
</style>