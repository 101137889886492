<!--  -->
<template>
    <div class='com-subject-summary-puzzle-item' :class="{'normal': !animation, 'ani-box': animation, 'filter-gray': !puzzleStatus}">
        <!-- <div class="puzzle-no-box position-center" :class="[state]">
            <span class="text-normal">{{info.puzzleNo}}</span>
        </div> -->
        <div class="normal-item" v-if="!last">
            <!-- <div class="item-content" :class="[state, {'ani-avai': userStarNum, 'ani-unavai': !userStarNum}]"> -->
            <div class="item-content ani-unavai" :class="[state]">
                <div class="content-avai" v-if="rankAvai && !animation"></div>
                <div class="content-animation" v-else-if="animation && !cheerStatus"></div>
                <!-- <div class="content-animation" v-else-if="animation && !userStarNum"></div> -->
            </div>
            <div class="item-shadow i-shadow" v-if="!rankAvai && !animation"></div>
            <div class="ing-shadow i-shadow" v-if="rankAvai"></div>
            <div class="normal-animation" v-if="rankAvai && !animation">
                <!-- <normalAnimation :urls="anumationUrls"></normalAnimation> -->
            </div>
        </div>
        <div class="last-item" v-else :class="{'cheered': cheerStatus}">
            <div class="body"></div>
        </div>
    </div>
</template>

<script>
    // import normalAnimation from '@/components/animation.vue'
    export default {
        name: '',
        props: {
            info: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {
                anumationUrls: [],
            };
        },
        methods: {
            async pageInit(){
                await this.animationInit()
            },
            async animationInit(){
                let num = 22
                for(let i = 0; i < num; i++){
                    this.anumationUrls.push(`${this.PARAMS.assetsUrlV2_0}/animation/com/action/${i}.png`)
                }
            },
        },
        created() {
            this.pageInit()
        },
        components: {
            // normalAnimation,
        },
        computed: {
            puzzleStatus(){
                let {info: {puzzleStatus} = 0} = this
                return puzzleStatus
            },
            last(){
                let {info: {last} = {}} = this
                return last
            },
            userStarNum(){
                let {info: {userStarNum} = {}} = this
                userStarNum = Number(userStarNum)
                if(isNaN(userStarNum)) userStarNum = 0
                return userStarNum
            },
            rankAvai(){
                let {rankAvai} = this.info
                return rankAvai
            },
            animation(){
                let {animation} = this.info
                return animation
            },
            cheerStatus(){
                let {cheerStatus} = this.info
                cheerStatus = Number(cheerStatus)
                if(isNaN(cheerStatus)) cheerStatus = 0
                return cheerStatus
            },
            state(){
                let {rankAvai, animation} = this
                if(animation) return 'animation'
                if(rankAvai) return 'ing'
                return this.color
            },
            color(){
                let {info = {}} = this
                if(info.userStatus && info.userStarNum) return 'black'
                if(info.userStatus && !info.rankAvai) return 'white'
				return info.userStatus || info.rankAvai ? 'black' : 'white'
            },
            rankNum(){
				let {info = {}} = this
                return info.puzzleNo
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-subject-summary-puzzle-item.normal{
        width: 36px;
        height: 36px;
    }
    .com-subject-summary-puzzle-item.ani-box{
        width: 41px;
        height: 41px;
    }
    .com-subject-summary-puzzle-item{
        position: relative;
        
        .normal-animation{
            width: 66px;
            height: 66px;
            position: absolute;
            top: -13px;
            left: -14px;
            .background-img-max(url('@{assetsUrlV2_0}/animation/com/action/puzzle.gif'));
            .com-global-animation-image{
                width: 100%;
                height: 100%;
            }
        }
        .item-content{
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
        .item-content.ing{
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_ing.png'));
        }
        .item-content.black{
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_black.png'));
        }
        .item-content.white{
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_white.png'));
        }
        
        
        .item-content.animation{
            // width: 41px;
            // height: 41px;
            width: 100%;
            height: 100%;
        }
        .item-content.animation.ani-unavai{
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_ani_1.png'));
        }
        .item-content.animation.ani-avai{
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_ani_3.png'));
        }
        .content-animation{
            // width: 73px;
            // height: 73px;
            // margin-top: -53px;
            // margin-left: -16px;
            width: 66px;
            height: 66px;
            margin-left: -13px;
            margin-top: -16px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_ani_4.png'));
        }
        .content-avai{
            width: 25px;
            height: 30px;
            margin: 0px auto;
            margin-top: -11px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_ing_stone.png'));
        }
        .i-shadow{
            position: absolute;
        }
        .item-shadow{
            z-index: 0;
            width: 37px;
            height: 34px;
            top: 5px;
            left: 0px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_item_shadow.png'));
        }
        .ing-shadow{
            width: 59px;
            height: 57px;
            top: -8px;
            left: -15px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/puz_ing_shadow.png'));
        }
        .last-item{
            position: relative;
            width: 113px;
            height: 102px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/g1/b70.png'));
            .body{
                position: absolute;
                left: 39px;
                top: 26px;
                width: 36px;
                height: 31px;
                .background-img-max(url('@{assetsUrlV2_0}/subject/g1/b71.png'));
            }
        }
        .puzzle-no-box.black{
            color: #fff;
        }
        .puzzle-no-box.white{
            color: #333;
        }
        .puzzle-no-box{
            z-index: 2;
        }
        .last-item.cheered{
            .body{
                .background-img-max(url('@{assetsUrlV2_0}/subject/puz_black.png'));

            }
        }
    }
</style>