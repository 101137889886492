<!--  -->
<template>
    <div class='com-subject0summary-head'>
        <div class="head-bcg"></div>
        <div class="head-select-box cursor-pointer" @click="optionsCon" subject-options-key="subject-options-value">
            <div class="text" v-show="selected.text">
                <span class="text-normal-c bold" :class="selected.value">{{selected.text}}</span>
                <div class="head-level-bottom"></div>
            </div>
        </div>
        <div class="head-select-opt" v-if="optionsVal" subject-options-key="subject-options-value">
            <div class="opt-item flex-center cursor-pointer" v-for="(v, i) in headList" :key="i" @click="change(v)">
                <span class="text-normal-c bold" :class="v.value">{{v.text}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            value: {
                type: String,
                default: 'g0'
            },
            headList: {
                type: Array,
                default(){
                    return []
                }
            }
        },
        data() {
            return {
                optionsVal: false
            };
        },
        methods: {
            eventSet(){
                this._common.addAppClick('subjectHead', this.appClick)
            },
            eventRemove(){
                this._common.removeAppClick('subjectHead')
            },
            appClick(e){
                let f = this._common.appClickCheck(e, 'subject-options-value', 'subject-options-key')
                if(!f) this.optionsOff()
            },
            optionsCon(){
                return this.optionsVal ? this.optionsOff() : this.optionsOn()
            },
            optionsOn(){
                this.optionsVal = true
            },
            optionsOff(){
                this.optionsVal = false
            },
            change(val){
                this.$emit('change', val)
                this.optionsOff()
            }
        },
        created() {
            this.eventSet()
        },
        components: {
        },
        computed: {
            selected(){
                let {headList: list, value: val} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == val){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventRemove()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-subject0summary-head{
        width: 654px;
        height: 63px;
        position: relative;
        .head-bcg{
            .element-position(100%, 100%, 0px, 0px, 1);
            .background-img-max(url('@{assetsUrlV2_0}/subject/c1.png'));
        }
        .head-select-box{
            .element-position(104px, 18px, 537px, 6px, 2);
            .background-img-max(url('@{assetsUrlV2_0}/subject/level.png'));
            .text{
                // width: 31px;
                height: 13px;
                margin: 0px auto;
                display: flex;
                justify-content: center;
                position: relative;
                .head-level-bottom{
                    .background-img-max(url('@{assetsUrlV2_0}/subject/level_bottom.png'));
                    width: 15px;
                    height: 15px;
                    position: relative;
                    top: 2px;
                    left: 3px;
                }
            }
        }
        .G0{
            color: #EFE271;
        }
        .G1{
            color: #9DDB69;
        }
        .G2{
            color: #ED9B72;
        }
        .head-select-opt{
            padding-top: 14px;
            .element-position(112px, 146px, 533px, 30px, 1);
            .background-img-max(url('@{assetsUrlV2_0}/subject/c33.png'));
            .opt-item{
                // .element-position(95px, 26px, 533px, 21px, 0);
                width: 95px;
                height: 26px;
                margin: 0px auto;
                .background-img-max(url('@{assetsUrlV2_0}/subject/c32.png'));
            }
            .opt-item+ .opt-item{
                margin-top: 7px;
            }
        }
    }
</style>