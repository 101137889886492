<!--  -->
<template>
    <div class='com-subject-summary-bcg position-center g0'>
        <component-bcg url="subject/g0/bcg.png"></component-bcg>
        <!-- :style="styleData" -->
        <div class="g-bcg" >
            <div class="item" v-for="(v, i) in bcgList" :key="i" :class="[v.class, v.extendClass]">
                <div class="item" v-for="(s, j) in v.children" :key="j" :class="s.class"></div>
            </div>
        </div>
        <div class="items-box">
            <slot name="items"></slot>
            <div class="item" v-for="(v, i) in itemsList" :key="`i-${i}`" :class="v.class" @click="funHand(v)">
                <div class="item" v-for="(s, j) in v.children" :key="`${i}-${j}`" :class="s.class"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                itemsList: [
                    {class: 'b30 scale-button', 
                        fun: 'gotoG1',
                        children: [
                            {class: 'b30-shadow'},
                            {class: 'b30-content'},
                        ]
                    }
                ],
                bcgList: [
                    {class: 'b1'}, //树
                    {class: 'b2'}, //草
                    {class: 'b3'}, //花
                    {class: 'b4'}, //植物阴影
                    {class: 'b5'}, //草阴影
                    {class: 'b6',
                        children: [
                            {class: 'b23'},
                            {class: 'b24'},
                            {class: 'b12'},
                            {class: 'b7'},
                            {class: 'b8'},
                            {class: 'b9'},
                            {class: 'b11'},
                            {class: 'b10'},
                            {class: 'b21'},
                        ]
                    }, //左侧山丘
                    {class: 'b13'}, //河流
                    {class: 'b14'}, //河流植物
                    {class: 'b15'}, //河流植物
                    {class: 'b16'}, //右侧山丘
                    {class: 'b17'}, //树
                    {class: 'b18'}, //树
                    {class: 'b19'}, //树阴影
                    {class: 'b20'}, //右侧山丘草
                    {class: 'b22'}, 
                    {class: 'b25'}, 
                    {class: 'b26'}, 
                    {class: 'b27'}, 
                    {class: 'b28'}, 
                    {class: 'b29'}, 
                    
                    // {class: 'b31'}, 
                    // {class: 'b30'}, 
                ]
            };
        },
        methods: {
            funHand(val){
                let {fun} = val || {}
                if(!this[fun]) return
                this[fun](val)
            },
            gotoG1(){
                this.$emit('gotoPuzzleLevel', 'G1')
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            styleData(){
                let {appSize} = this._common
                if(!appSize) return {}
                return {
                    width: `${appSize.width}px`
                }
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前c2
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-subject-summary-bcg.g0{
        width: 100vw;
        height: 100vh;
        // width: 667px;
        // height: 375px;
        .item-params(@width, @height, @left: auto, @top: auto, @zIndex: 0){
            width: @width;
            height: @height;
            left: @left;
            top: @top;
            z-index:  @zIndex;
        }
        // div[class^='puz-item']{
        //     position: absolute;
        // }
        .items-box{
            z-index: 1;
            .puzzle-item.is-last{
                display: none;
            }
            .puzzle-item{
                position: absolute;
            }
            .puz-item-1{
                left: 69px;
                top: 95px;
            }
            .puz-item-2{
                left: 180px;
                top: 65px;
            }
            .puz-item-3{
                left: 289px;
                top: 98px;
            }
            .puz-item-4{
                left: 398px;
                top: 69px;
            }
            .puz-item-5{
                left: 500px;
                top: 76px;
            }
            .puz-item-6{
                left: 573px;
                top: 128px;
            }
            .puz-item-7{
                left: 560px;
                top: 216px;
            }
            .puz-item-8{
                left: 492px;
                top: 285px;
            }
        }
        .items-box, .g-bcg{
            position: absolute;
            width: @designWidth;
            height: @designHeight;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        // .g-bcg{
            
            z-index: 0;
            .item{
                position: absolute;
            }
            .item.b1{
                .item-params(634px, 308px, 27px, 2px, 1);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c2.png'));
            }
            .item.b2{
                .item-params(541px, 332px, 118px, 41px, 1);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c3.png'));
            }
            .b3{
                .item-params(653px, 309px, 14px, 57px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c4.png'));
            }
            .b4{
                .item-params(628px, 331px, 30px, 45px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c5.png'));
            }
            .b5{
                .item-params(530px, 302px, 133px, 52px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c6.png'));
            }
            .b6{
                .item-params(188px, 166px, 0px, 209px, 1);
            }
            .b7{
                .item-params(55px, 69px, 0px, 34px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c8.png'));
            }
            .b8{
                .item-params(74px, 48px, 9px, 118px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c9.png'));
            }
            .b9{
                .item-params(59px, 44px, 75px, 122px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c10.png'));
            }
            .b10{
                .item-params(57px, 46px);
                right: -68px;
                top: 120px;
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c11.png'));
            }
            .b11{
                .item-params(60px, 75px);
                right: -97px;
                top: 88px;
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c12.png'));
            }
            .b12{
                .item-params(80px, 65px, 54px, 0px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c13.png'));
            }
            .b13{
                .item-params(413px, 276px, 192px, 0px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c14.png'));
            }
            .b14{
                .item-params(295px, 133px, 243px, 126px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c15.png'));
            }
            .b15{
                .item-params(146px, 127px, 248px, 150px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c16.png'));
            }
            .b16{
                .item-params(650px, 129px, 17px, 246px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c17.png'));
            }
            .b17{
                .item-params(77px, 48px, 557px, 327px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c18.png'));
            }
            .b18{
                .item-params(60px, 42px, 436px, 333px, 1);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c19.png'));
            }
            .b19{
                .item-params(43px, 28px, 427px, 42px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c20.png'));
            }
            .b20{
                .item-params(36px, 24px, 623px, 329px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c21.png'));
            }
            .b21{
                .item-params(23px, 13px, 38px, 92px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c22.png'));
            }
            .b22{
                .item-params(39px, 22px, 624px, 105px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c23.png'));
            }
            .b23{
                .item-params(123px, 172px, 71px, -6px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c24.png'));
            }
            .b24{
                .item-params(188px, 166px, 0px, 0px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c7.png'));
            }
            .b25{
                .item-params(348px, 210px, 192px, 23px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c25.png'));
            }
            .b26{
                .item-params(631px, 308px, 0px, 55px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c26.png'));
            }
            .b27{
                .item-params(70px, 57px, 486px, 69px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c27.png'));
            }
            .b28{
                .item-params(57px, 18px, 489px, 115px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c28.png'));
            }
            .b29{
                .item-params(59px, 22px, 487px, 111px);
                .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c29.png'));
            }
            // .b30{
            //     .item-params(154px, 106px, 258px, 234px);
            //     .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c30.png'));
            // }
            // .b31{
            //     .item-params(125px, 64px, 281px, 277px);
            //     .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c31.png'));
            // }
            .b30{
                .item-params(154px, 106px, 258px, 234px);
                .b30-content{
                    width: 100%;
                    height: 100%;
                    .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c30.png'));
                }
                .b30-shadow{
                    .background-img-max(url('@{assetsUrlV2_0}/subject/g0/c31.png'));
                    position: absolute;
                    left: 23px;
                    bottom: -1px;
                    width: 125px;
                    height: 64px;
                }
            }
        // }
    }
</style>