import commonApi from '../_EXT_API'

export default class game extends commonApi{
	constructor(opt){
		opt = opt || {}
		let localKey = 'subject'
		let name = 'subject'
		opt = {localKey, name}
		super(opt)
	}
	async  getSubjectDirectory(opt) {
		let {user_code, school_id, page, size, course_id} = opt
        let data = {school_id, page, size, course_id}
		let options = {
			method: "get",  
			user_code,
			data,
		}
		let res = await this.requestAction('getSubjectDirectory', options)
		return res
	}
	async puzzleList(opt) {
		let {puzzle_id} = opt
		let options = {
			method: "get",
			puzzle_id:puzzle_id,
			data:{
			}
		}
		return await this.requestAction('puzzleList', options)
	}
	async exerciseList(opt) {
		let {puzzle_id} = opt
		let options = {
			method: "get",
			puzzle_id:puzzle_id,
			data:{
			}
		}
		let res = await this.requestAction('exerciseList', options)
        if(!res) return
        return this._common.mindExerciseHandler(res)

	}
	async uploadPuzzle(opt) {
		let {user_code,exercise_id,user_answer,correct} = opt
		let options = {
			method: "post",
			user_code:user_code,
			data:{
				exerciseId:exercise_id, 
				userAnswer:user_answer,
				correct:correct,
			}
		}
		return await this.requestAction('uploadPuzzle', options)
	}
	async uploadUserAnswer(opt) {
		let {exerciseId, userAnswer, correct, answerDuration = 0} = opt || {}
		let options = {
			method: "post",
			...opt,
			// headers: {
			// 	'Content-Type': 'application/json;charset=UTF-8'
			// },
			textRequest: true,
			data:{
				exerciseId, 
				userAnswer,
				correct,
                answerDuration
			}
		}
		return await this.requestAction('uploadUserAnswer', options)
	}
	async puzzleSituation(opt) {
		let {user_code,exercise_size} = opt
		let options = {
			method: "get",
			user_code:user_code,
			data:{
				exercise_size:exercise_size,
			}
		}
		return this.requestAction('puzzleSituation', options)
	}
	async puzzleInfo(opt) {
		let {puzzle_id} = opt
		let options = {
			method: "get",
			...opt,
			data:{
				puzzle_id,
			}
		}
		let res = await this.requestAction('puzzleInfo', options)
		if(res) this.balanceCheck()
		return res
	}

	async puzzleResult(opt) {
		let {puzzle_id} = opt
		let options = {
			method: "get",
			...opt,
			data:{
				puzzle_id,
			}
		}
		let res = await this.requestAction('puzzleResult', options)
		return res
	}

	async nextPuzzle(opt){
		let {puzzle_no, school_id = this.PARAMS.school_id, course_id} = opt
        let data = {puzzle_no, school_id, course_id}
		let options = {
			method: "get",
			...opt,
			data
		}
		return this.requestAction('nextPuzzle', options)
	}

	async puzzleBasicInfo(opt){
		let options = {
			method: "get",
			...opt,
			data:{
			}
		}
		return this.requestAction('puzzleBasicInfo', options)
	}

	async userPuzzleInfo(opt){
		opt = opt || {}
		let {usercode} = opt
        usercode = usercode || this._appData.getItem('usercode', 'app')
		opt = {
			...opt,
			usercode
		}
		let options = {
			method: "get",
			...opt,
			data:{
			}
		}
		return this.requestAction('userPuzzleInfo', options)
	}

	async puzzleAnimationList(opt){
		opt = opt || {}
		let {usercode, courseId: course_id} = opt
        let data = {course_id}
        usercode = usercode || this._appData.getItem('usercode', 'app')
		opt = {
			...opt,
			usercode
		}
		let options = {
			method: "get",
			...opt,
			data
		}
		return this.requestAction('puzzleAnimationList', options)
	}

	async puzzleAnimationClick(opt){
		opt = opt || {}
		let {usercode, cheerId: cheer_id, status: cheer_status} = opt
		usercode = usercode || this._appData.getItem('usercode', 'app')
		cheer_status = cheer_status ? 1 : 0
        if(!cheer_id) return
		opt = {
			...opt,
			usercode
		}
		let options = {
			method: "post",
			...opt,
			data:{
				cheer_id,
				cheer_status
			}
		}
		return this.requestAction('puzzleAnimationClick', options)
	}

    async puzzleAccomplish(opt){
        opt = opt || {}
		let {usercode, course_id} = opt
		usercode = usercode || this._appData.getItem('usercode', 'app')
        if(!course_id || !usercode) return
		opt = {
			...opt,
			usercode
		}
		let options = {
			method: "get",
			...opt,
            usercode,
			data:{
				course_id,
			}
		}
		return this.requestAction('puzzleAccomplish', options)
    }
	
}
