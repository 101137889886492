<!--  -->
<template>
 <!-- v-if="active" -->
    <div class='com-subject-puzzle-animation flex-center' v-if="active" :style="boxStyle" >
        <!-- <div class="ani-body" :style="bodyStyle"></div> -->
        <img :src="`${imgPath}`" class="ani-body" v-if="!last">
        <div class="ani-body-box" v-else :class="{'active': openActive}">
            <div class="body-text">
                <span class="text-large-bb bold">{{text}}</span>
            </div>
            <div class="body-award">
            
            </div>
        </div>
    </div>
</template>

<script>
    import apiClass from '@/API/page/subject'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                active: false,
                openActive: false,
                response: null,
                info: {},
                puzzleLevelList: []
            };
        },
        methods: {
            async configLoad(){
                if(this.puzzleLevelList && this.puzzleLevelList.length) return
                let res = await this.api.configLoad('puzzle')
                if(res) this.puzzleLevelList = res
            },
            paramsInit(val){
                this.response = null
                this.info = {}
                if(val) this.info = val
            },
            async open(val){
                this.paramsInit(val)
                await this.configLoad()
                return new Promise(res => {
                    this.response = res
                    this.openAction()
                })
                
            },
            async openAction(){
                this.active = true
                this.voicePlay()
                await this.animationOn()
                await this._common.settimeout(this.dration)
                await this.animationOff()
                this.close()
            },
            async animationOn(){
                await this._common.settimeout(50)
                await this._common.nextTick.call(this)
                this.openActive = true
                await this._common.settimeout(300)
                return true
            },
            async animationOff(){
                this.openActive = false
                await this._common.settimeout(300)
                return true
            },
            close(){
                this.active = false
                if(this.response) this.response()
            },
            voicePlay(){
                let {info: {last, courseId}} = this
                if(!last || !courseId) return
                this._voiceHand.normalPlay('puzzle end')
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            text(){
                let {text} = this.selected
                let txt = '恭喜您获得{text}通关奖杯1个'
                txt = this._common.urlParse(txt, {text})
                return txt
            },
            puzzleLevelListCul(){
                let {puzzleLevelList: list} = this
                for(let i in list){
                    let tem = list[i]
                    try{
                        list[i] = {
                            ...list[i],
                            ...list[i].resourceDetail
                        }
                    }catch(e){ list[i] = tem }
                }
                return list
            },
            selected(){
                let {puzzleLevelListCul: list, info: {courseId: value}} = this
                let key = 'courseId'
                let {data} = this._common.selected({list, value, key})
                return data ||{}
            },
            dration(){
                let {last} = this
                return last ? 2000 : 2500
            },
            last(){
                let info = this.info || {}
                return info.last
            },
            imgPath(){
                let {courseId, last} = this.info
                let root = this.PARAMS.assetsUrlV2_0
                let path = `${root}/animation/puzzle_normal.gif`
                if(last) path = `${root}/animation/puzzle_${courseId}.gif`
                // let t = (new Date()).getTime()
                // path += `?${t}`
                return path
            },
            boxStyle(){
                let {last} = this.info
                if(!last) return {}
                return {
                    background: 'none'
                }
            },
            bodyStyle(){
                let path = this.imgPath
                return {
                    background: `url('${path}')`,
                    backgroundSize: '100% 100%'
                }
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-subject-puzzle-animation{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 302;
        .ani-body{
            width: @designWidth;
            height: @designHeight;
        }
        .ani-body-box.active{
            transform: scale(1);
        }
        .ani-body-box{
            transition: all .3s;
            transform: scale(0);
            position: relative;
            width: 552px;
            height: 346px;
            .background-img-max(url('@{assetsUrlV2_0}/animation/puzzle_end.png'));
            .body-text{
                position: absolute;
                top: 214px;
                left: 151px;
                width: 283px;
                text-align: center;
                color: #fff;
            }
            .body-award{
                position: absolute;
                top: 303px;
                left: 243px;
                width: 100px;
                height: 35px;
                .background-img-max(url('@{assetsUrlV2_0}/animation/puzzle_end_award.png'));
            }
        }
    }
</style>