<!--  -->
<template>
    <div class='com-subject-summary-background'>
        <g0 v-if="selected.value == 'G0'" :styleClass.sync="styleClass" @gotoPuzzleLevel="gotoPuzzleLevel">
            <template v-slot:items v-if="$slots.items">
                <slot name="items"></slot>
            </template>
        </g0>
        <g1 v-if="selected.value == 'G1'" :styleClass.sync="styleClass" @gotoPuzzleLevel="gotoPuzzleLevel">
            <div slot="items" v-if="$slots.items">
                <slot name="items"></slot>
            </div>
        </g1>
    </div>
</template>

<script>
    import g0 from './g0.vue'
    import g1 from './g1.vue'

    export default {
        name: '',
        props: {
            value: String,
            appStyle: Object,
            styleClass: String,
        },
        data() {
            return {
                list: [
                    {value: 'G0', default: false},
                    {value: 'G1', default: true},
                ]
            };
        },
        methods: {
            gotoPuzzleLevel(val){
                this.$emit('gotoPuzzleLevel', val)
            }
        },
        created() {

        },
        components: {
            g0,
            g1
        },
        computed: {
            selected(){
                let {value, list} = this
                let defaultData = null, rd = null
                for(let i in list){
                    let {default: de} = list[i]
                    if(de) defaultData = list[i]
                    if(list[i].value == value){
                        rd = list[i]
                        break
                    }
                }
                if(!rd && defaultData) rd = defaultData
                return rd || {}
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >

</style>