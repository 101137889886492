<!--  -->
<template>
    <div class='subject-summary-index'>
        <div class="summary-head">
            <summaryHead
            :headList.sync="headListCul"
            :value="selected.value"
            @change="headChange"
            ></summaryHead>
        </div>
        <div class="index-bcg">
            <bcg :value="selected.value" :list="headList" :styleClass.sync="styleClass" @gotoPuzzleLevel="gotoPuzzleLevel">
                <template v-slot:items>
                    <div class="puzzle-item scale-button" v-for="(v, i) in listCulNew" :key="i" :class="v.class" @click="gotoAction(v)">
                        <puzItem :info="v"></puzItem>
                    </div>
                    <div class="page-item left cursor-pointer" v-if="!isFirstPage && listLoaded" @click="pageChange(Number(page) - 1)"
                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                    ></div>
                    <div class="page-item right cursor-pointer" v-if="!isLastPage && listLoaded" @click="pageChange(Number(page) + 1)"
                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                    ></div>
                </template>
            </bcg>
        </div>
        
        <puzzleAnimation ref="puzzleAnimation"></puzzleAnimation>
    </div>
</template>

<script>
    import apiClass from '@/API/page/subject'
    import summaryHead from './head.vue'
    import bcg from './bcg/index.vue'
    import puzItem from './components/puzzleItem.vue'
    import puzzleAnimation from './components/puzzleAnimation.vue'

    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                api: new apiClass(),
                head: [
                    {title: 'G0体验训练营', value: 'G0'},
                    {title: 'G1启蒙阶段', value: 'G1'},
                    {title: 'G2提升阶段', value: 'G2'},
                ],
                headList: [],
                routeData: this.$route.params.routeData,
                size: this.PARAMS.subjectPageSize,
                puzzleList: [],
                puzzleCount: 0,
                lineNum: 5,
                pageDestroy: false,
                maxUserPuzzleInfo: {},
                backgroundList: [
                    'G0', 'G1'
                ],
                animationList: [],
                listLoaded: false
            };
        },
        methods: {
            async dataLoad(){
                let {loadParams} = this
                this.puzzleList = []
                await this.animationListInit()
                let res = await this.api.getSubjectDirectory(loadParams)
                if(!res) return
                this.puzzleList = res.puzzleList
                this.puzzleCount = res.puzzleCount
                this.listLoaded = true
            },
            async pageInit(){
                this.voiceOpen()
                await this.userPuzzleInfoInit()
                await this.configLoad()
                await this.routeDataCheck()
                
            },
            async configLoad(){
                let res = await this.api.configLoad('puzzle')
                if(res){
                    this.headList = res
                    // this.headValCheck()
                }
            },
            async routeDataCheck(){
                let params = {}
                let {page, selected: {value: headVal}} = this
                if(page === undefined) params.page = this.userMaxPuzzlePage.page || 0
                if(!headVal){
                    let {value} = this.headListCul[0] || {}
                    params.headVal = this.userMaxPuzzlePage.headVal || value
                }
                this.refreshPage({items: params})
                await this._common.nextTick.call(this)
                return true
            },
            refreshPage(opt){
                if(this.pageDestroy) return false
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                let query = this.$route.query
                return this._common.refreshPage.call(this, {items, name, type, query})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'subject-summary-full'
                type = type || 'replace'
                let query = this.$route.query
                return this._common.refreshPageAction.call(this, {name, params, type, query})
            },
            headChange(val){
                let {value: headVal} = val || {}
                if(!headVal) return
                let items = {
                    headVal,
                    page: 0
                }
                this.listLoaded = false
                this.refreshPage({items})
            },
            pageChange(page){
                page = Number(page)
                if(page < 0 || page >= this.pageCount || isNaN(page)) return
                let items = {page}
                this.refreshPage({items})
            },
            gotoAction(val){
                let {userStatus, rankAvai, puzzleId, animation, puzzleStatus, userStarNum} = val || {}
                puzzleStatus = Number(puzzleStatus)
                if(!puzzleStatus) return this.gotoDisabledNotice(val)
                if(animation) return this.animationOpen(val)
                if((!userStatus && !rankAvai) || (!rankAvai && !userStarNum)){
                    return this.gotoDisabledNotice(val)
                }
                if(!puzzleId){
                    return
                }
                let name = 'subject-action'
                let params = {puzzleId}
                params = {
                    routeData: this._common.encodeData(params)
                }
                let query = this.$route.query
                this._routeHand.goto({name, params, query})
            },
            gotoDisabledNotice(val){
                let {puzzleStatus} = val || {}
                let message = this.pageText.t1, confirmType = 'c1', buttons = []
                if(!puzzleStatus) message = this.pageText.t2, confirmType = 'c3', buttons = [{title: '确定', type: 'confirm', label: 0}]
                // this._common.toastOpen({
                //     message,
                //     getContainer: '.subject-summary-index',
                //     className: 'subject-list-toast'
                // })
                this._common.confirmPro.open({
                    message,
                    confirmType,
                    buttons
                })
            },
            rankLineActiveGet(i, list){
                let {lineNum} = this
                i = Number(i)
                let index = parseInt((i) / lineNum) + 1
                let f = false
                if(index % 2 != 0) {
                    f = list[i].userStatus ? true : false
                }else{
                    let nextData = list[i + 1]
                    if(!nextData || !nextData.userStatus) f = false
                    else f = true
                }
                return f
            },
            rankAvaiGet(i, list, lastVal){
                lastVal = lastVal || {}
                if(lastVal.animation && !lastVal.cheerStatus) return false
                let data = list[i]
                let {userStatus, userStarNum, puzzleNo} = data
                if(userStarNum > 0) return false
                return data.userStatus ? true : false
            },
            isLast(j, k){
                let {listCul: list} = this
                let subList = list[j] || {}
                subList = subList.list || []
                if(j != list.length - 1) return false
                if(j % 2 == 0){
                    return k == subList.length - 1
                }else{
                    return k == 1
                }
            },
            itemClass(index){
                return `puz-item-${index}`
            },
            animationPuzzleCul(no){
                let {animationPuzzle} = this
                if(!animationPuzzle) return false
                return no % animationPuzzle == 0
            },
            animationPush(val, nVal){
                let animationData = this.getAnimationData(val, nVal)
                if(!animationData) return false
                let {animationPuzzle} = this
                let {puzzleNo: no = 0} = val || {}
                if(!animationPuzzle || !no || no % animationPuzzle != 0) return false
                let index = no + 1 % (this.size)
                let data = {
                    ...val,
                    animation: true,
                    ...animationData
                }
                delete data.puzzleId
                return data
            },
            async animationOpen(val){
                let {userStarNum, cheerStatus, cheerId, userStatus} = val
                userStarNum = Number(userStarNum)
                if(!userStarNum && !userStatus) return this.gotoDisabledNotice(val)
                // if(!userStarNum) return this.gotoDisabledNotice(val)
                await this.$refs['puzzleAnimation'].open(val)
                if(!cheerStatus){
                    if(val.last){
                        let res = await this.finishedPuzzleAward()
                        if(!res) return
                    }
                    let status = true
                    let res = await this.api.puzzleAnimationClick({cheerId, status})
                    if(res) this.setAnimationStatus(cheerId, status)
                    
                }
                if(val.last){
                    let res = await this.nextPuzzleInit(val)
                    if(!res) return
                    this.gotoNextPuzzle(res)
                }
            },
            async finishedPuzzleAward(){
                let {courseId: course_id} = this.selected
                let res = await this.api.puzzleAccomplish({course_id})
                // if(!res) return 
                return res

            },
            gotoNextPuzzle(val){
                let {puzzleLevel} = val || {}
                if(!puzzleLevel) return
                if(!this.isInPuzzleList(puzzleLevel)) return
                this.headChange({value: puzzleLevel})
            },
            isInPuzzleList(value){
                
                let {headListCul: list} = this
                let {data} = this._common.selected({list, value, key: 'value'})
                if(!data || !data.value) return false
                return true
            },
            async nextPuzzleInit(val){
                let {
                    selected: {
                        courseId: course_id,

                    },
                    usercode: user_code
                } = this
                let {puzzleNo: puzzle_no} = val || {}

                if(!user_code || !course_id || !puzzle_no) return
                let res = await this.api.nextPuzzle({puzzle_no, user_code, course_id})
                if(!res) return res
                let puzzleLevel = this.getPuzzleLevelByConfig(res)
                if(puzzleLevel) res.puzzleLevel = puzzleLevel || ''
                return res
                // if(!res) return
                // res = res || {}
                // this.nextPuzzle = res
                // return res
            },
            getPuzzleLevelByConfig(res){
                let {courseId} = res
                if(!courseId) return null
                let {headListCul: list} = this
                let level = null
                for(let i in list){
                    if(list[i].courseId == courseId){
                        level = list[i].value
                        break
                    }
                }
                return level
            },
            setAnimationStatus(cheerId, status){
                let {animationList: list} = this
                for(let i in list){
                    if(list[i].cheerId == cheerId){
                        list[i].cheerStatus = status
                        break
                    }
                }
            },
            async userPuzzleInfoInit(){
                let res = await this.api.userPuzzleInfo()
                if(!res) return
                let {maxUserPuzzleRecord = {}, townPuzzle = {}} = res
                this.maxUserPuzzleInfo = {
                    ...maxUserPuzzleRecord,
                    ...townPuzzle
                }
            },
            nextPuzzleCheck(list){
                list = list || []
                let f = true
                for(let i in list){
                    let {userStarNum} = list[i]
                    userStarNum = Number(userStarNum)
                    if(!userStarNum ){
                        this.gotoDisabledNotice(list[i])
                        f = false
                        break
                    }
                }
                return f
            },
            gotoPuzzleLevel(val){
                let {headListCul: list} = this
                if(!this.nextPuzzleCheck(this.listCulNew)) return 
                let f = null
                for(let i in list){
                    if(list[i].value == val){
                        f = list[i].value
                        break
                    }
                }
                if(!f) return
                this.headChange({value: f})
            },
            voiceOpen(){
                this._voiceHand.normalPlay('subject bcg')
            },
            voiceClose(){
                this._voiceHand.normalStop('subject bcg')
            },
            async animationListInit(){
                let {selected: {courseId}} = this
                this.animationList = []
                if(!courseId) return
                let res = await this.api.puzzleAnimationList({courseId})
                if(res) this.animationList = res
            },
            getAnimationData(val, nextVal){
                let {animationList: list} = this
                
                let {puzzleNo, last} = val || {}
                if(last){
                    puzzleNo = this.selected.courseId * this.PARAMS.last_puzzle_cheer_basic
                    if(isNaN(puzzleNo)) puzzleNo = val.puzzleNo
                }
                let data = null
                for(let i in list){
                    if(list[i].cheerPos == puzzleNo){
                        data = list[i]
                        break
                    }
                }
                if(nextVal && data){
                    let {userStarNum} = nextVal
                    if(userStarNum) data.cheerStatus = 1
                }
                return data
            }
        },
        created() {
            // this._common.confirmPro.open()
        },
        components: {
            // rankItem,
            // rankItemLine,
            // rankItemLineLink,
            // rankLinePage,
            summaryHead,
            bcg,
            puzItem,
            puzzleAnimation
        },
        computed: {
            maxPuzzleInfoPuzzleLevel(){
                let {headList: list, maxUserPuzzleInfo: {courseId}} = this
                let {data} = this._common.selected({list, value: courseId, key: 'courseId'})
                data = data || {}
                return data.puzzleLevel
            },
            puzzleMaxNo(){
                let {puzzleMaxNo: list} = this.PARAMS
                let {headVal} = this
                if(!headVal || !list[headVal]) return null
                return list[headVal]
            },
            userMaxPuzzlePage(){
                let {maxUserPuzzleInfo: info, size, headListCul: list} = this
                
                let {puzzleNo, puzzleLevel, courseId} = info
                let {data} = this._common.selected({list, value: courseId, key: 'courseId'})
                data = data || {}
                if(!puzzleNo) return 0
                let page = Math.ceil(puzzleNo / size)
                page--
                return {page, headVal: data.value}
            },
            styleClass(){
                let {styleNumCul} = this
                if(styleNumCul === null) return ''
                let key = 'page-'
                if(this.pageCul <= 1) key += 'start'
                else if(this.pageCount <= this.pageCul) key += 'end'
                else key += styleNumCul
                return key
            },
            styleNumCul(){
                let {styleNum} = this
                if(!styleNum) return null
                return this.page % styleNum
            },
            lastRankData(){
                let {listCul: list = []} = this
                let lastData = list[list.length - 1] || {}
                lastData = lastData.list || []
                lastData = lastData[lastData.length - 1] || {}
                return lastData
            },
            firstRankData(){
                let {listCul: list = []} = this
                let firstData = list[0] || {}
                firstData = firstData.list || []
                firstData = firstData[0] || {}
                return firstData
            },
            headListCul(){
                let {headList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {resourceDetail = {}} = list[i]
                    list[i] = {...resourceDetail}
                }
                return list
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            pageCount(){
                let {puzzleCount, size} = this
                return Math.ceil(puzzleCount / size)
            },
            isFirstPage(){
                return this.page <= 0
            },
            isLastPage(){
                if(!this.listLoaded) return false
                return this.page >= (this.pageCount - 1)
            },
            listCulNew(){
                let {puzzleList: list, lineNum, page} = this
                list = this._common.deepCopy(list)
                let rd = []
                if(page > 0 && list[0]){
                    let tem = this._common.deepCopy(list[0])
                    let nTem = list[1]
                    tem.puzzleNo--
                    let val = this.animationPush(tem, nTem)
                    if(val){
                        val.class = this.itemClass(rd.length + 1)
                        let {maxUserPuzzleInfo: {puzzleId}} = this
                        if(puzzleId == tem.puzzleId) val.userStarNum = 1
                        rd.push(val)
                    }
                }
                for(let i in list){
                    let {userStatus} = list[i] || {}
                    list[i].rankAvai = this.rankAvaiGet(i, list, rd[rd.length - 1])
                    let no = Number(rd.length) + 1
                    list[i].class = this.itemClass(no)

                    
                    rd.push(list[i])
                    if(i != list.length - 1){
                        let val = this.animationPush(list[i], list[Number(i) + 1])
                        if(val){
                            val.class = this.itemClass(rd.length + 1)
                            rd.push(val)
                        }
                    }
                    
                }
                if(this.isLastPage && list.length && !this.isFirstPage && list.length <= this.PARAMS.puzzle_last_num){
                    // let lastData = {
                    //     ...list[list.length - 1],
                    //     animation: true,
                    //     last: true,
                    //     class: 'is-last'
                    // }
                    // let {courseId} = this.selected
                    // let cheerId = courseId * this.PARAMS.last_puzzle_cheer_basic
                    let aniData = this.animationPush({...list[list.length - 1], last: true})
                    rd.push({
                        ...aniData,
                        animation: true,
                        last: true,
                        class: 'is-last'
                    })
                }
                return rd
            },
            listCul(){
                let {puzzleList: list, lineNum} = this
                list = this._common.deepCopy(list)
                let rd = [], fun = 'push', tem = []
                for(let i in list){
                    let {userStatus} = list[i] || {}
                    list[i].rankAvai = this.rankAvaiGet(i, list)
                    fun = rd.length % 2 == 0 ? 'push' : 'unshift'
                    list[i].rankLineActive = this.rankLineActiveGet(i, list)
                    tem[fun](this._common.deepCopy(list[i]))
                    if((Number(i) + 1) % lineNum == 0){
                        let data = {list: tem}
                        let {userStatus} = tem[tem.length - 1] || {}
                        let neIndex = Number(i) + 1
                        if(list[neIndex] && list[neIndex].userStatus) data.active = true
                        else data.active = false
                        rd.push(data)
                        tem = []
                    }
                }
                if(tem.length){
                    let data = {list: tem}
                    let {userStatus} = tem[tem.length - 1] || {}
                    data.active = userStatus ? true : false
                    rd.push(data)
                    tem = []
                }
                for(let i in rd){
                    let {list = []} = rd[i]
                    if(list.length == lineNum) continue
                    let num = lineNum - list.length
                    let fun = i % 2 != 0 ? 'unshift' : 'push'
                    for(let j = 1; j <= num; j++){
                        list[fun]({none: true})
                    }
                    rd[i].list = list
                }
                return rd
            },
            loadParams(){
                // let {app: {usercode: user_code} = {}} = this.$store.state
                let {usercode: user_code} = this
                let {school_id} = this.PARAMS
                let {
                    selected: {courseId: course_id}, 
                    page, size
                } = this
                return {
                    user_code,
                    school_id,
                    course_id,
                    page,
                    size
                }
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            pageCul(){
                return Number(this.page) + 1
            },
            page(){
                let {page} = this.routeDataCul
                return page
            },
            headVal(){
                let {headVal} = this.routeDataCul
                return headVal
            },
            selected(){
                let {headListCul: list, headVal: val} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == val){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            styleNum(){
                let {style_num} = this.selected
                return style_num
            },
            animationPuzzle(){
                // let {pageCul} = this
                // let list = this.selected[`animation_puzzle_${pageCul}`] || ''
                // let data = {}
                // try{
                //     list = list.split(',')
                // }catch(e){ list = [] }
                // for(let i in list){
                //     if(!list[i] || list[i] == '' || list[i] == ' ') continue
                //     data[list[i]] = true
                // }
                // return data
                let {animation_puzzle} = this.selected
                return animation_puzzle
            },
            loadWatch(){
                let {page, selected: {value}} = this
                if(!value || page === undefined || isNaN(Number(page))) return false
                return true
            }
        },
        watch: {
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
            loadWatch: {
                handler(val, oldval){
                    if(!val) return
                    this.dataLoad()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.pageInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageDestroy = true
            this.voiceClose()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-summary-index{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        .summary-head{
            position: absolute;
            width: 100%;
            top: 0px;
            left: 0px;
            z-index: 1;
            
        }
        .page-item{
            width: 45px;
            height: 51px;
            position: absolute;
            top: 58px;
        }
        .page-item.left{
            left: 120px;
            top: 316px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/page-left.png'));
        }
        .page-item.right{
            left: 501px;
            top: 316px;
            .background-img-max(url('@{assetsUrlV2_0}/subject/page-right.png'));
        }
    }
</style>